import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { v4 as uuid } from 'uuid'
import { Link } from 'gatsby'
import { toCurrency } from '../../utils/format'
import { CART_QUERY, CHECKOUT_QUERY } from '../../graphql/queries'
import PlusBtn from '../Product/PlusBtn'
import MinusBtn from '../Product/MinusBtn'
import { QuantityBtn, BtnPrimary, BtnSecondary } from '../elements/btn'
import EmptyCart from '../EmptyCart'
import {
  CartCard,
  CartCardWrapper,
  CartCardImage,
  CartCardDescription,
  StyledCart,
  CartCardDetails,
  CartCardQuantityAndPrice,
  CartCheckoutWrapper,
  CartTotalTable,
  CartTotalRow,
  CartContinueCheckout,
  CartTermsAndConditions,
  // CartDropdown,
  CartDateAndAdress,
} from './style'

const Cart = () => {
  const { data } = useQuery(CART_QUERY)
  const getWithdrawalDetails = useQuery(CHECKOUT_QUERY)
  const withdrawalDetails = getWithdrawalDetails.data.checkout

  const withdrawalDay = () => {
    const dateToday = new Date()
    const day = dateToday.getDay()

    if (day === 5 || day === 6 || day === 0) {
      dateToday.setDate(dateToday.getDate() + 4)
    } else if (day === 1) {
      dateToday.setDate(dateToday.getDate() + 3)
    } else dateToday.setDate(dateToday.getDate() + 2)

    const options = { weekday: 'long', month: 'long', day: 'numeric' }

    return dateToday.toLocaleDateString('fr-FR', options)
  }

  return (
    <>
      {data.cart.order_items.length === 0 ? (
        <EmptyCart />
      ) : (
        <StyledCart>
          <div>
            {/* (orderItem: OrderItem) */}
            {data.cart.order_items.map((orderItem) => (
              <CartCard key={uuid()}>
                <CartCardWrapper>
                  <CartCardDetails>
                    <CartCardImage>
                      <img src={`${orderItem.product.thumbnail_url}&w=500&h=400`} />
                    </CartCardImage>
                    <CartCardDescription>
                      <h3>{orderItem.product.title}</h3>
                      <p>{orderItem.product.description}</p>
                    </CartCardDescription>
                  </CartCardDetails>
                  <CartCardQuantityAndPrice>
                    <QuantityBtn>
                      <PlusBtn productSku={orderItem.product.sku} />
                      <p>{orderItem.quantity}</p>
                      <MinusBtn productSku={orderItem.product.sku} />
                    </QuantityBtn>
                    <div className="price">{toCurrency(orderItem.product.price)}</div>
                  </CartCardQuantityAndPrice>
                </CartCardWrapper>
              </CartCard>
            ))}
          </div>
          <CartCheckoutWrapper>
            <CartTotalTable>
              <CartDateAndAdress>
                <h3>
                  Votre commande sera disponible <span>{withdrawalDay()}</span> de{' '}
                  <span>
                    {withdrawalDetails.withdrawal_hour_start} à{' '}
                    {withdrawalDetails.withdrawal_hour_end}
                  </span>{' '}
                  à <span> {withdrawalDetails.address}</span>
                </h3>
              </CartDateAndAdress>
            </CartTotalTable>
            {/* Withdrawal address & date for next iteration wirh dropdown
            
            <CartTotalTable>
              <CartDropdown>
                <span className="label">Veuillez sélectionner une date</span>
                <select id="country">
                  <option>Mardi</option>
                  <option>Mercredi</option>
                  <option>Jeudi</option>
                  <option>Vendredi</option>
                  <option>Samedi</option>
                </select>
                <p>Commande disponible du <span>mardi</span> au <span>vendredi</span> en <span>48h</span></p>
              </CartDropdown>
            </CartTotalTable>
            <CartTotalTable>
              <CartDropdown>
                <span className="label">Et l'adresse de votre choix</span>
                <select id="country">
                  <option>Boulangerie Louise Halluin</option>
                  <option>Boulangerie Louise Roncq</option>
                  <option>Boulangerie Louise Wattrelos</option>
                  <option>Boulangerie Louise Lomme</option>
                </select>
              </CartDropdown>
            </CartTotalTable> */}
            <CartTotalTable>
              <CartTotalRow>
                <h3>Total</h3>
                <div className="total-cost">{toCurrency(data.cart.total)}</div>
              </CartTotalRow>
            </CartTotalTable>
            <CartContinueCheckout>
              <BtnPrimary>Commander</BtnPrimary>
              <Link to="/">
                <BtnSecondary>Continuer mes achats</BtnSecondary>
              </Link>
            </CartContinueCheckout>
          </CartCheckoutWrapper>
          <CartTermsAndConditions>
            <p>
              En effectuant un achat, vous acceptez les <br />
              <Link to="/conditions-generales">Conditions Générales de Marque</Link>
            </p>
          </CartTermsAndConditions>
        </StyledCart>
      )}
    </>
  )
}

export default Cart
