// import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import Layout from '../components/layout'
import Container from '../components/elements/container'
import Cart from '../components/Cart'
import H2 from '../components/elements/headings'
import Section from '../components/elements/section'

const PanierPage: FunctionComponent = () => (
  <Layout>
    <Container>
      <Section>
        <H2>Mon Panier</H2>
        <Cart />
      </Section>
    </Container>
  </Layout>
)

export default PanierPage
