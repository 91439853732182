import * as React from 'react'
import { Link } from 'gatsby'
import { BtnPrimary } from '../elements/btn'
import { StyledEmptyCart, EmptyCartDescriptionAndBtn } from './style'

const EmptyCart = () => {
  return (
    <StyledEmptyCart>
      <div className="content">
        <EmptyCartDescriptionAndBtn>
          <p>Votre panier est vide</p>
          <Link to="/" className="button">
            <BtnPrimary>Commencer mes achats</BtnPrimary>
          </Link>
        </EmptyCartDescriptionAndBtn>
      </div>
    </StyledEmptyCart>
  )
}

export default EmptyCart
