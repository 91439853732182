import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledEmptyCart = styled.div`
  ${tw`
    w-full
  `}
  .content {
    ${tw`
      mt-12
      text-left
    `}
  }
`

export const EmptyCartDescriptionAndBtn = styled.div`
  p {
    ${tw`
      mb-1
      text-gray-500
      font-semibold
    `}
  }
  a {
    ${tw`
      mt-5
    `}
  }
`
