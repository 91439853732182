/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledCart = styled.div`
  ${tw`
    mt-12
    lg:max-w-none
  `}
`

export const CartCard = styled.div`
  ${tw`
    flex
    flex-col
    rounded-lg
    shadow-lg
    bg-white
    overflow-hidden
    p-5
    my-3
    w-full
  `}
`

export const CartCardWrapper = styled.div`
  ${tw`
    flex
    flex-col
    md:flex-row
  `}
`

export const CartCardImage = styled.div`
  ${tw`
    flex-shrink-0
  `}
  img {
    ${tw`
      h-16
      w-20
      object-cover
      rounded-lg
    `}
  }
`

export const CartCardDescription = styled.div`
  ${tw`
    pl-3
  `}
  h3 {
    ${tw`
      text-xs
      font-bold
      text-gray-900
      mb-1
      block
      leading-none
    `}
  }
  p {
    ${tw`
      text-xs
      text-gray-500
    `}
  }
`

export const CartCardDetails = styled.div`
  ${tw`
    flex
    flex-grow
    md:w-1/2
    md:items-center
  `}
`

export const CartCardQuantityAndPrice = styled.div`
  ${tw`
    md:w-1/3
    lg:w-1/5
    mt-5
    md:mt-0
    flex
    items-center
    justify-between
  `}
  .price {
    ${tw`
      flex
      justify-end
      text-sm
    `}
  }
`

export const CartCheckoutWrapper = styled.div`
  ${tw`
    mt-6
    rounded-lg
    shadow-lg
    bg-white
  `}
`

export const CartTotalTable = styled.div`
  ${tw`
    border-b
    border-gray-200
    relative
    p-5
  `}
`

export const CartDropdown = styled.span`
  ${tw`
    relative
    z-0
    flex
    flex-wrap
  `}
  select {
    ${tw`
      -ml-px
      block
      w-full
      pl-3
      pr-9
      py-2
      rounded-md
      border
      border-gray-300
      bg-white
      text-sm
      leading-5
      font-medium
      text-gray-700
      hover:text-gray-500
      focus:z-10
      focus:outline-none
      focus:border-blue-300
      focus:shadow-outline-blue
      active:bg-gray-100
      active:text-gray-700
      transition
      ease-in-out
      duration-150
      shadow-sm
    `}
  }
  .label {
    ${tw`
      text-sm
      mb-3
      text-gray-900
    `}
  }
  p {
    ${tw`
      text-xs
      mt-1
      text-gray-900
    `}
    span {
      ${tw`
        font-bold
      `}
    }
  }
`

export const CartDateAndAdress = styled.div`
  ${tw`
    text-sm
    mt-1
    text-gray-900
  `}
  span {
    ${tw`
      font-bold
    `}
  }
`

export const CartTotalRow = styled.div`
  ${tw`
    flex
    items-center
    justify-between
  `}
  h3 {
    ${tw`
      text-gray-900
      text-2xl
      font-bold
      justify-between
      w-3/4
    `}
  }
  .total-cost {
    ${tw`
      text-gray-900
      text-base
      text-right
    `}
  }
`

export const CartContinueCheckout = styled.div`
  ${tw`
    p-5
    flex
    flex-wrap
    md:w-1/2
    md:w-1/3
    mx-auto
  `}
  button {
    ${tw`
      w-full
    `}
  }
  a {
    ${tw`
      w-full
      mt-5
    `}
  }
`

export const CartTermsAndConditions = styled.div`
  p {
    ${tw`
      my-5
      text-xs
      text-center
      text-gray-500
    `}
    a {
      ${tw`
        font-bold
        text-gray-900
      `}
    }
  }
`
